import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
          <i>“The Pillars of Tomorrow”</i>
        </div>
        <div className="card-body">
          <p>Brethren,</p>
          <p>
            Spring is upon us-a season of renewal, growth, and new
            opportunities. As nature awakens, so too should our commitment to
            one another, our Lodge, and our community. Let this time of rebirth
            inspire us to reflect on the past and set meaningful goals for the
            year ahead, striving to be better men in all that we do.
          </p>

          <h3>Caring for Our Brothers and Community</h3>
          <p>
            With the warmer weather, we encourage all brethren to reach out to
            our older members and their families. A simple visit or phone call
            can mean the world to those who may feel isolated. In addition, if
            any of our brethren or widows need assistance with yard cleanup or
            gardening, we stand ready to help. Please reach out if you or
            someone you know could use a helping hand.
          </p>

          <h3>Lodge Clean-Up and Improvement Day</h3>
          <p>
            To prepare for the upcoming months of fellowship and events, we are
            planning a Lodge Clean-Up Day within the next two months. This will
            focus on landscaping and repairs to our back patio, creating a
            welcoming space for gatherings. Stay tuned for a confirmed date, and
            let's come together to make our Lodge shine.
          </p>

          <h3>Looking Ahead</h3>
          <p>
            As we move forward in this season of renewal, let's challenge
            ourselves to grow-not just as Masons but as men dedicated to living
            by the principles of our Craft. Whether through acts of charity,
            personal reflection, or strengthening our bonds of brotherhood, let
            this spring be a time of purposeful action.
          </p>

          <p>
            We look forward to seeing you at our upcoming meetings and events.
            May this season bring you joy, strength, and the opportunity to make
            a difference.
          </p>

          <h2>Solomon's Circle:</h2>

          <p>
            The next few sessions of Solomon's Circle will be discussing a book
            titled "Freemasonry's Hidden Brain Science" by by Michael
            Schiavello. You can find a copy at Macoy's Publishing or on Amazon.
            ISBN-10 0853185654
          </p>
          <p>
            Description: This is Freemasonry in its ultimate expression, deeper
            and more vast than you could ever have imagined. Offering a
            revolutionary approach to the Craft, Freemasonry's Hidden Brain
            Science explains and proves the genius of Masonic ritual as a
            neurological, psychological, and metaphysical handbook for
            self-improvement and the attainment of higher consciousness.{" "}
          </p>

          <p>
            Best-selling author, television personality, and 32° Freemason,
            Michael Schiavello, reveals startling discoveries made while
            plumbing the depths of Masonic ritual and symbolism.
          </p>

          <p>
            Discover how King Solomon's Temple correlates specifically to the
            makeup and measurements of the human nervous system, and unlock the
            true meaning of the Masonic pillars, Hiram Abiff, the Three
            Ruffians, the Legend of the Third Degree, Euclid's 47th Proposition,
            the Tyler's sword, Caution, the Past Master Jewel, the obligation to
            not make women Freemasons, and more.
          </p>

          <p>
            Unfolding like a self-help treasure map, Freemasonry's Hidden Brain
            Science reveals hidden meanings found within Freemasonry's
            allegories and symbolism, which go far beyond their literal
            interpretations and will change the way you think, act, and feel.
          </p>

          <p>
            Foreword by Pearl Harbor survivor Edward Hall 33°. Paperback, 230
            pages.
          </p>

          <p>
            Come see what it's about - 2nd and 4th Wednesdays at Prince George
            Lodge No. 115. Open to all Master Masons.
          </p>
        </div>
        <div className="card-footer">
          <p>Donald H. Rackley Jr.</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
