import React from "react";

const Home = () => {
  return (
    <>
      <div style={{ marginTop: "50px" }} className="container-fluid">
        <p className="firstHead">Prince George Lodge No. 115 AF &amp; AM</p>
        <p className="secondHead">Stated Communication: 1st Thursday</p>
        <p className="secondHead">Meeting at 7:30 p.m.; Dinner at 6:30 p.m.</p>
        <img
          className="img-fluid"
          src="/images/lodge.jpg"
          alt="Lodge Building"
        />
        <div className="row">
          <div className="col-md-5 col-xs-8">
            <div>Prince George Lodge No. 115</div>
            <div>5709 Ruffin Road</div>
            <div>Prince George, VA 23875</div>
          </div>
          <div className="col-md-4 col-xs-4">
            <img
              className="img-fluid"
              style={{ marginTop: "10px" }}
              src="/images/150pin.jpg"
              alt="Prince George Lodge No. 115"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h2>Next Stated</h2>
            <p>
              The March 6th stated meeting will begin at 7:30 p.m. Dinner will
              start at 6:30.
            </p>
            <p>
              The menu for dinner: Roast Pork Loin Wrapped in Bacon with an
              Apricot Glaze, Mashed Potatoes, Green Beans, and a surprise
              dessert
            </p>
          </div>

          <div className="col-md-6">
            <h2>Upcoming Events</h2>
            <ul>
              <li>
                Wednesday, March 12 and March 26 at 6 pm - Solomon's Circle
                Ritual and Education at Prince George Lodge
              </li>
              <li>Tuesday, March 25 at 7 pm - District Ritual School</li>
            </ul>
          </div>

          <div className="col-md-6">
            <h2>Fundraisers</h2>
            <ul>
              <li>Friday, April 18 (Good Friday), Fish Fry at the Lodge</li>
              <li>
                Wednesday, May 14, Harold Jackson Bibey, Sr. Memorial Golf
                Tournament
              </li>
              <li>Saturday, July 19, Barbecue at the Lodge</li>
              <li>Saturday, Nov. 1, Brunswick Stew</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
